.colBox {
  width: 120px;
  height: 70px;
  background-color: #ffffff;
  border: 1px solid #000000;
  position: relative;
  .posAbs {
    position: absolute;
    margin-right: 0px !important;
  }
  .leftTop {
    left: 0;
    top: 0;
  }
  .rightTop {
    right: 0;
    top: 0;
  }
  .leftBottom {
    left: 0;
    bottom: 0;
  }
  .rightBottom {
    right: 0;
    bottom: 0;
  }
}
