@import "~@/styles/global_font.less";

.stream-login-content {
  height: 100%;
  // min-height: 700px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;

  .stream-login-rignt {
    height: 100%;
    overflow-y: auto;
    display: flex;
    padding-bottom: 40px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .login-page-form {

      input,
      .password,
      .btn-primary {
        width: 360px;
        vertical-align: middle;
        padding-bottom: 0;
        padding-top: 0;
        box-sizing: content-box;
        height: 40px;
      }

      .register-text {
        margin-top: 25px;
      }

      .sanfang-text {
        margin: 10px 0;
      }

      .third-party-icon {
        font-size: 68px;
        cursor: pointer;
      }
    }

    .zdy-password {
      position: relative;
      background-color: #fff;

      .zdy-password-icon {
        position: absolute;
        cursor: pointer;
        right: 16px;
        top: 55%;
        font-size: 14px;
        color: #666;
      }
    }

    .login-logo {
      text-align: center;

      img {
        width: 240px;
        margin: 80px 0;
      }

    }

    .ft {
      // position: absolute;
      margin-top: 20px;
      text-align: center;
      color: @font-color-blue;
    }
  }


}

.login-loading {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -200%);
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 7px 30px 7px 20px;
  font-weight: 300;
  user-select: none;

  img {
    width: 25px;
    height: auto;
  }

  span {
    margin-left: 15px;
  }
}