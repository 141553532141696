.cdn-scroll {
  height: 100%;
  overflow: auto;
}

html,
body {
  font-size: 14px;
  min-height: 100%;
}
.height-fill {
  height: 100%;
}

.stream-page {
  padding-top: 15px;
  padding-bottom: 15px;
}

.stream-page-row {
  padding-left: 15px;
  padding-right: 15px;
}
.ant-modal-title{
  font-weight: bold;
  color: #1E2D42;
  font-size: 26;
}
.ant-layout {
  background-color: #fff;
  min-height: 100%;
}
.ant-table-cell {
  background-color: #fff !important;
}
.text-center{
  text-align: center;
}