.stream-side {
  padding: 25px 10px 15px 10px;
  overflow-y: auto;
  height: 100% ;
  overflow-x: hidden;
  ol {
    padding-left: 0;
    li {
      list-style-type: none; 
      margin-top: 15px;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      .menu-text {
        color: #0f324e;
      }
      .menu-icon-rd {
        height: 50px;
        width: 50px;
        text-align: center;
        .str-icon {
          position: relative;
          color: #999;
          font-size: 20px;
        }
      }
      &.selected {
        color: #72affd;
        background-color: #fff;
        .str-icon {
          // bottom: 3px;
          font-size: 20px;
          color: #fff;
          z-index: 1;
        }
        .stream-side-icon-bg {
          display: inline-block;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .stream-side-icon-bg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    margin: 0;
    border-radius: 50%;
    background-color: #aad0ff;
    line-height: 56px;
    text-align: center;
    span {
      display: inline-block;
      height: 38px;
      width: 38px;
      margin-top: 6px;
      border-radius: 50%;
      background-color: #72affd;
    }
  }
}