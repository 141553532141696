.stream-category-menu {
  background-color: #fff;
  width: 450px;
  border-radius: 3px;
  .menu-loginout-row {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  .category-dom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 110px;
    span:nth-child(1) {
      width: 50px;
      height: 50px;
      // text-align: center;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ebf3fd;
      color: #72acff;
      margin-bottom: 5px;
    }
    span:nth-child(2){
        color: #333;
        font-weight: 600;
    }
    &:hover {
      color: red;
      cursor: pointer;
    }
  }
}
