.comp-template{
  .ant-pagination-options{
    .ant-select-lg{
      .ant-select-selector{
        height: 32px !important;
      }
      .ant-select-selection-item{
        line-height: 32px !important;
      }
    }
  }

  .bottom-15{
    padding-bottom: 15px;
  }
}
