.comp-clip{
    border: 1px solid #e3e3e3;
    line-height: 40px;
    padding: 0 10px;
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 30px;
    .comp-clip-icon{
        font-size: 0.5em;
    }
}