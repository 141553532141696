.comp-global-dialog{
    display: flex;
    align-items: center;

    font-size: 1.2em;
    margin-top: 20px;
    padding-left: 10px;

    p{
        margin: 0px;
        margin-left: 10px;
    }
}
