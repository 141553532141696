.comp-header {
  padding: 10px 30px;
  line-height: 64px;
  color: #0F324E;
  height: 84px;
  background-color: #fff;
}

.license-header-box {
  width: 100%;
  height: 80px;
  background-color: #0F324E;
}

.license-header {
  padding-top: 10px;
  padding-left: 10px;
}

.license-title {

  margin-top: 10px;
  color: darkgray;
}

.license-button {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 15px;

  span {
    text-decoration: underline;
  }
}

.fold-arrow {
  color: #fff;
  padding-top: 40px;
}

.docu-dropdown-item a:hover {
  color: #1890ff;
}

// .dierge-xiala-header {
//   .ant-dropdown{
//     margin-top: 100px !important;
//   }
// }