.upload-video-copm {
  width: 100%;
  height: 130px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  &:hover {
    border-color: #40a9ff;
  }
  p {
    text-align: center;
    line-height: 60px;
  }
}
